<template>
  <a-dims-search-bar hide-search>
    <div class="flex uppercase justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-medium">Seguridad</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div v-if="!loadings.user" class="pt-16 px-4">
    <div class="space-y-12">
      <div class="flex flex-col space-y-2">
        <div class="text-left">
          <div class="border-b flex flex-col items-start">
            <label for="new_email" class="text-lg text-gray-800">Correo electrónico</label>
            <input @input="clear('new_email')" type="new_email" v-model="user.new_email" class="border-none underlined py-2 text-lg w-full focus:outline-none" id="new_email">
          </div>
          <small class="text-red-500" v-if="has('new_email')">{{ get('new_email') }}}</small>
        </div>

        <div class="flex justify-end">
          <button @click="processType = 'email'" :disabled="loadings.email || user?.new_email === current_user.email" class="bg-primary text-white inlint-flex text-lg rounded-full p-2 px-6 disabled:bg-gray-200">
            <a-loader color="text-primary" class="h-6 w-6" v-if="loadings.email" />
            <template v-else>
              Actualizar
            </template>
          </button>
        </div>
      </div>

      <div class="flex flex-col space-y-2">
        <div class="text-left">
          <label for="new_password" class="text-lg text-gray-800">Nueva contraseña</label>
          <div class="border-b flex items-start">
            <input @input="clear('new_password')" type="password" v-model="user.new_password"
                   class="flex-1 w-full border-none underlined py-2 text-lg w-full focus:outline-none" id="new_password" ref="password1">
            <i @click="revealPassword" class="flex-none icon-eye px-2 text-xl font-bold"></i>
          </div>
          <small class="text-red-500" v-if="has('new_password')">{{ get('new_password') ? 'Los datos ingresados son invalidos' : '' }}</small>
        </div>

        <div class="text-left">
          <label for="new_password_confirmation" class="text-lg text-gray-800">Confirmación de nueva contraseña</label>
          <div class="border-b flex items-start">
            <input @input="clear('new_password_confirmation')" type="password" v-model="user.new_password_confirmation"
                   class="flex-1 w-full border-none underlined py-2 text-lg focus:outline-none" id="new_password_confirmation" ref="password2">
            <i @click="revealPassword2" class="flex-none icon-eye px-2 text-xl font-bold"></i>
          </div>
          <small class="text-red-500" v-if="has('new_password_confirmation')">{{ get('new_password_confirmation') ? 'Los datos ingresados son invalidos' : '' }}}</small>
        </div>

        <div class="flex justify-end">
          <button @click="processType = 'password'" :disabled="loadings.password || !user.new_password || !user.new_password_confirmation" class="bg-primary text-white inlint-flex text-lg rounded-full p-2 px-6 disabled:bg-gray-200">
            <a-loader color="text-primary" class="h-6 w-6" v-if="loadings.password" />
            <template v-else>
              Actualizar
            </template>
          </button>
        </div>
      </div>
    </div>
  </div>

  <a-alert
    title="Un ultimo paso"
    :show="processType !== null"
    @confirm="update"
    @cancel="processType = null">
    <h1>Para proceder, por favor introduzca su contraseña</h1>
    <div class="flex items-center">
      <input v-model="password" @input="clear('password')" type="password" placeholder="Contraseña" class="flex-1 mt-2 focus:outline-none" ref="password3">
      <i @click="revealPassword3" class="flex-none icon-eye px-2 text-xl font-bold cursor-pointer"></i>
    </div>
    <small class="text-red-500" v-if="has('password')">{{ get('password') }}</small>

    <template #confirm>
      <a-loader color="text-primary" class="h-6 w-6" v-if="Object.values(loadings).some($0 => $0)" />
      <template v-else>
        Continuar
      </template>
    </template>
  </a-alert>
  <a-alert :show="modal.success" hide-cancel @confirm="modal.success = false">
    <div class="flex space-x-4 items-start">
      <i class="icon-checkmark p-4 text-xl bg-green-500 rounded-full text-white shadow-lg" />
      <div class="flex flex-col">
        <h1 class="text-black text-xl">¡Guardado!</h1>
        <h1 class="text-xl mt-2">Tu contraseña se ha actualizado correctamente</h1>
      </div>
    </div>
  </a-alert>
  <a-alert :show="modal.fail" hide-cancel @confirm="modal.fail = false">
    <div class="flex space-x-4 items-start">
      <i class="icon-close p-4 text-xl bg-red-500 rounded-full text-white shadow-lg" />
      <div class="flex flex-col">
        <h1 class="text-black text-xl">¡Error!</h1>
        <h1 class="text-xl mt-2">Tu contraseña no se ha podido actualizar</h1>
      </div>
    </div>
  </a-alert>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    user: {
      new_email: '',
      new_password: '',
      new_password_confirmation: ''
    },
    modal: {
      success: false,
      fail: false
    },
    password: '',
    loadings: {
      email: false,
      password: false,
      user: false
    },
    current_user: {},
    processType: null
  }),
  computed: {
    ...mapState({
      current: ({ session }) => session.user
    }),
    ...mapGetters(['has', 'get'])
  },
  methods: {
    ...mapMutations({}),
    ...mapActions(['clear', 'vanishErrors']),
    update () {
      const func = this[`update_${this.processType}`]
      if (func && typeof func === 'function') {
        func()
      }
    },
    update_password () {
      this.loadings.password = !this.loadings.password
      return this.$repository.users
        .updatePassword(this.current_user.id, {
          password: this.password,
          new_password: this.user.new_password,
          new_password_confirmation: this.user.new_password_confirmation
        })
        .then(() => {
          this.vanishErrors()
          this.processType = null
          this.modal.success = true
        })
        .catch(() => (this.modal.fail = true))
        .finally(() => (this.loadings.password = !this.loadings.password))
    },
    update_email () {
      this.loadings.email = !this.loadings.email
      return this.$repository.users
        .updateEmail(this.current_user.id, {
          password: this.password,
          new_email: this.user.new_email
        })
        .then(() => {
          this.vanishErrors()
          this.processType = null
          this.$router.go()
        })
        .finally(() => (this.loadings.email = !this.loadings.email))
    },
    revealPassword () {
      const password = this.$refs.password1
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    },
    revealPassword2 () {
      const password = this.$refs.password2
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    },
    revealPassword3 () {
      const password = this.$refs.password3
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    }
  },
  mounted () {
    this.loadings.user = true
    this.$repository.users
      .show(this.current.id)
      .then(({ data: response }) => {
        this.current_user = response
        this.user.new_email = response.email
      })
      .finally(() => {
        this.loadings.user = false
      })
  }
}
</script>
